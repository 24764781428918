import React, { useEffect } from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Link, Icon, LinkBox, Section, Image } from "@quarkly/widgets";
import TagManager from 'react-gtm-module';
import WavedropperSignature from './WavedropperSignature';
import { FaFacebook, FaInstagram, FaLinkedinIn, FaYoutube } from "react-icons/fa";
const tagManagerArgs = {
	gtmId: 'GTM-K5G2P6X4'
};
export const sendEvent = args => {
	TagManager.dataLayer(args);
};
const defaultProps = {
	"padding": "80px 0 50px 0",
	"background": "--color-darkL1",
	"quarkly-title": "Footer-17",
	"overflow-x": "hidden",
	"overflow-y": "hidden"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"lg-width": "100%",
			"flex-direction": "row",
			"lg-flex-direction": "column",
			"justify-content": "space-between",
			"width": "100%",
			"padding": "0 0px 0 0px",
			"sm-flex-direction": "column",
			"sm-padding": "0 0px 0 0px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"lg-margin": "0px 0px 0px 0px",
			"width": "50%",
			"display": "flex",
			"lg-width": "70%",
			"sm-width": "100%",
			"flex-direction": "column",
			"padding": "0px 50px 0px 0px",
			"lg-padding": "0px 0 0px 0px",
			"sm-margin": "0px 0px 35px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"color": "--light",
			"letter-spacing": "1px",
			"font": "--lead",
			"children": "KAPCSOLAT"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0 0px 35px 0px",
			"font": "--base",
			"color": "#c3c8d0",
			"sm-text-align": "left",
			"sm-margin": "0 0px 0 0px",
			"lg-max-width": "640px",
			"children": <>
				Legyen szó kérdésről, megjegyzésről vagy javaslatról, mindig örömmel fogadjuk megkeresésedet.{" "}
				<Link href="https://sziklaybence.com/about/#contact" target="_blank" color="--secondary" text-decoration-line="initial">
					Írj nekünk itt
				</Link>
				.
			</>
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"grid-template-columns": "repeat(2, 1fr)",
			"grid-gap": "54px",
			"lg-align-items": "start",
			"md-grid-template-columns": "repeat(3, 1fr)",
			"md-grid-gap": "36px 34px",
			"sm-grid-template-columns": "1fr",
			"sm-grid-gap": "16px 0",
			"justify-content": "flex-end",
			"sm-flex-direction": "column",
			"display": "flex",
			"lg-justify-content": "flex-start",
			"lg-display": "flex"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"align-items": "flex-start",
			"margin": "0px 0px 0px 0",
			"lg-align-items": "flex-start",
			"justify-content": "flex-start",
			"display": "grid",
			"lg-flex-direction": "column",
			"lg-margin": "0px 0px 0px 0px",
			"flex-direction": "column",
			"align-content": "start",
			"grid-gap": "10px 0",
			"font": "--base"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"border-color": "--color-primary",
			"display": "flex",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"margin": "0px 0 0px 0",
			"lg-border-width": "0px 0px 0px 2px",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "#ffffff",
			"letter-spacing": "1px",
			"children": "ÁSZF",
			"href": "https://sziklaybence.com/terms",
			"target": "_blank"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"display": "flex",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "#ffffff",
			"letter-spacing": "1px",
			"children": "Sütik",
			"href": "../cookies",
			"target": "_blank"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "#ffffff",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"letter-spacing": "1px",
			"children": "Asdf",
			"href": "#",
			"display": "none"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"justify-content": "space-between",
			"padding": "50px 0px 0px 0px",
			"border-color": "#232a44",
			"md-padding": "30px 0px 0px 0px",
			"md-flex-direction": "column",
			"lg-padding": "40px 0px 0px 0px",
			"align-items": "flex-end",
			"height": "auto",
			"md-align-items": "flex-start"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "#c3c8d0",
			"md-margin": "0px 0px 25px 0px",
			"children": "© 2025 Digital Neurons Ltd. All rights reserved."
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"grid-template-columns": "repeat(5, 1fr)",
			"grid-gap": "16px 24px",
			"md-align-self": "flex-start"
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"href": "https://www.facebook.com/sziklaybenceofficial/",
			"target": "_blank"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "fa",
			"icon": FaFacebook,
			"size": "24px",
			"color": "#c3c8d0",
			"hover-color": "white",
			"transition": "background-color 1s ease 0s"
		}
	},
	"linkBox1": {
		"kind": "LinkBox",
		"props": {
			"href": "https://www.instagram.com/sziklaybenceofficial/",
			"target": "_blank"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"category": "fa",
			"icon": FaInstagram,
			"size": "24px",
			"color": "#c3c8d0",
			"hover-color": "white",
			"transition": "background-color 1s ease 0s"
		}
	},
	"linkBox2": {
		"kind": "LinkBox",
		"props": {
			"href": "https://www.youtube.com/@sziklaybenceofficial",
			"target": "_blank"
		}
	},
	"linkBoxLinkedIn": {
		"kind": "LinkBox",
		"props": {
			"href": "https://www.linkedin.com/in/ben-sz/",
			"target": "_blank"
		}
	},
	"iconLinkedIn": {
		"kind": "Icon",
		"props": {
			"category": "fa",
			"icon": FaLinkedinIn,
			"size": "24px",
			"color": "#c3c8d0",
			"hover-color": "white",
			"transition": "background-color 1s ease 0s"
		}
	},
	"icon2": {
		"kind": "Icon",
		"props": {
			"category": "fa",
			"icon": FaYoutube,
			"size": "24px",
			"color": "#c3c8d0",
			"hover-color": "white",
			"transition": "background-color 1s ease 0s"
		}
	},
	"linkBox3": {
		"kind": "LinkBox",
		"props": {
			"href": "https://www.tiktok.com/@sziklaybenceofficial",
			"target": "_blank"
		}
	},
	"icon3": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/655c9b91867c86001f18116f/images/tiktok.svg?v=2023-12-01T22:54:55.299Z",
			"height": "23px",
			"hover-color": "white",
			"filter": "--invert",
			"opacity": "0.7",
			"transition": "background-color 1s ease 0s"
		}
	},
	"box-right": {
		"props": {}
	},
	"CreatorSignature": {
		"props": {
			"bottom": 0,
			"left": "50vw",
			"position": "static",
			"margin": "30px 5px 5px 0",
			"justify-content": "flex-start"
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	useEffect(() => {
		TagManager.initialize(tagManagerArgs);
	}, []);
	return <>
		<style>
			{`
          a#CybotCookiebotDialogPoweredbyCybot,
          div#CybotCookiebotDialogPoweredByText {
            display: none;
          }

          #CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
            display: none!important; 
          }
					#CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-change {
						background-color: #487ccf!important;
						border-color: #487ccf!important;
					}
					#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-consents-list li.CookiebotWidget-approved svg {
    				fill: #487ccf!important;
					}
        `}
		</style>
		<Section {...rest}>
			<Override slot="SectionContent" justify-content="flex-start" />
			<Box {...override("box")}>
				<Box {...override("box1")}>
					<Text {...override("text")} />
					<Text {...override("text1")} />
				</Box>
				<Box {...override("box2")}>
					<Box {...override("box3")}>
						<Link {...override("link")} />
						<Link {...override("link1")} />
						<Link {...override("link2")} />
					</Box>
				</Box>
			</Box>
			<Box {...override("box4")}>
				<Text {...override("text2")} />
				<Box {...override("box5")}>
					<LinkBox {...override("linkBox")}>
						<Icon {...override("icon")} />
					</LinkBox>
					<LinkBox {...override("linkBox1")}>
						<Icon {...override("icon1")} />
					</LinkBox>
					<LinkBox {...override("linkBox3")}>
						<Image {...override("icon3")} />
					</LinkBox>
					<LinkBox {...override("linkBoxLinkedIn")}>
						<Icon {...override("iconLinkedIn")} />
					</LinkBox>
					<LinkBox {...override("linkBox2")}>
						<Icon {...override("icon2")} />
					</LinkBox>
				</Box>
			</Box>
			<WavedropperSignature {...override('CreatorSignature')} />
			{children}
		</Section>
	</>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;